// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_n\\+g8a{padding:1rem}.container_n\\+g8a,.end_05yVX,.start_5uWas{display:flex;flex-direction:column}.start_5uWas{border-right:1px solid var(--color-black-50);justify-content:center;max-width:12rem}.controls_v0fMZ,.copy_dqfEF,.start_5uWas{padding:1.5rem}.controls_v0fMZ{border-top:1px solid var(--color-black-50);display:flex}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_n+g8a",
	"end": "end_05yVX",
	"start": "start_5uWas",
	"controls": "controls_v0fMZ",
	"copy": "copy_dqfEF"
};
module.exports = ___CSS_LOADER_EXPORT___;
